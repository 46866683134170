<template>
    <b-overlay :show="loading">
        <b-card class="mb-2">
            <b-row clas="align-items-center">
                <b-col sm="12" md="6">
                    <h2>{{ audit ? audit.no : '' }}</h2>
                </b-col>
                <b-col sm="12" md="6" class="text-right">
                    <b-button variant="outline-primary" @click.prevent="print">
                        <feather-icon icon="PrinterIcon" />
                        Cetak Audit
                    </b-button>
                </b-col>
            </b-row>
            <table>
                <tr>
                    <td>Tanggal</td>
                    <td>:</td>
                    <th>{{audit ? audit.tanggal : ''}}</th>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>:</td>
                    <th>
                        <b-badge variant="light-success" v-if="audit.status == 1">Selesai</b-badge>
                        <b-badge variant="light-danger" v-else>Belum Selesai</b-badge>
                        <strong class="ml-1 cursor-pointer text-success" @click="confirmSelesai" v-if="audit.status == 0">Tandai Selesai?</strong>
                    </th>
                </tr>
                <tr>
                    <td>Keterangan</td>
                    <td>:</td>
                    <th>{{audit ? audit.keterangan : ''}}</th>
                </tr>
            </table>
        </b-card>
        <h4 class="mb-1">Rincian Aset <span v-if="rincians.length > 0">({{rincians.length}})</span></h4>
        <b-card class="mb-2">
            <b-button v-if="audit.status == 0" class="mb-2" variant="primary" @click.prevent="openAssetModal">Pilih Aset</b-button>
            <b-table :fields="fields" :items="rincians">
                <template #cell(no)="{index}">
                    {{ ++index }}
                </template>
                <template #cell(nama)="{item}">
                    <strong v-if="item.penyimpanan && item.penyimpanan.asset">
                    {{ item.penyimpanan.asset.nama }}</strong>
                    <i class="text-danger" v-else>Asset tidak ditemukan</i>
                </template>
                <template #cell(blok)="{item}">
                    <strong v-if="item.penyimpanan && item.penyimpanan.blok">
                    {{ item.penyimpanan.blok.blok }}</strong>
                    <i class="text-danger" v-else>Lokasi Aset tidak ditemukan</i>
                </template>
                <template #cell(jumlah)="{item}">
                    <b-form-input type="number" v-model="item.jumlah"></b-form-input>
                </template>
                <template #cell(selisih_barang)="{item}">
                    {{ parseInt(item.jumlah) - parseInt(item.stok) }}
                </template>
                <template #cell(action)="{item, index}">
                    <feather-icon v-if="audit && audit.status == 0" class="text-danger cursor-pointer" icon="XCircleIcon" size="24" @click="remove(item, index)" />
                </template>

            </b-table>
            <br>
            <div class="d-flex justify-content-end">
                <b-button variant="primary" :disabled="rincians.length < 1 || audit.status == 1" @click.prevent="submit">
                {{ audit.status == 0 ? 'Simpan' : 'Audit sudah diselesaikan' }}
                </b-button>
            </div>
        </b-card>
        <penyimpanan-modal @submit="onChooseStock"></penyimpanan-modal>
    </b-overlay>
</template>
<script>
import {BRow, BCol, BBadge, BFormInput, BOverlay, BCard, BTable, BButton} from 'bootstrap-vue'
import PenyimpananModal from './components/Modal.vue'
export default {
    components: {
        BRow, BCol, BBadge, BFormInput, BOverlay, BCard, BTable, BButton, PenyimpananModal
    },
    computed: {
        audit() {
            return this.$store.getters['asset/searchAudit'](this.$route.params.id)
        }
    },
    data: () => ({
        rincians: [],
        fields: [
            {key: 'no', label: 'No'},
            {key: 'nama', label: 'Aset'},
            {key: 'blok', label: 'Lokasi Aset'},
            {key: 'stok', label: 'Stok Aset'},
            {key: 'jumlah', label: 'Hasil Audit'},
            {key: 'selisih_barang', label: 'Selisih Barang'},
            {key: 'action', label: '#'}
        ],
        loading: false,
        selesaiOptions: {
            title: 'Anda yakin?',
            text: "Setelah selesai, anda tidak bisa merubah data ini",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false
        }
    }),
    methods: {
        async print() {
            try {
                this.loading = true
                const response = await this.$store.dispatch('asset/printAudit', this.$route.params.id)
                this.loading = false
                window.open(URL.createObjectURL(response))
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        confirmSelesai() {
            this.$swal(this.selesaiOptions)
            .then(async res => {
                if(res.value) {
                    const payload = Object.assign({}, this.audit)
                    payload.status = 1
                    try {
                        this.loading = true
                        await this.$store.dispatch('asset/saveAudit', [payload])
                        this.loading = false
                        this.displaySuccess({
                            message: 'Audit ini berhasil diselesaikan!'
                        })
                        setTimeout(() => this.$router.push(`/asset-audit`), 1000)
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        async remove(item, index) {
            if(item.id) {
                const payload = {id: item.id, fungsi: 2}
                try {
                    await this.$store.dispatch('asset/saveAuditRincian', [payload])
                    this.rincians.splice(index, 1)
                }
                catch(e) {
                    this.displayError(e)
                    return false
                }
            }
            else {
                this.rincians.splice(index, 1)
            }
        },
        onChooseStock(selectedStocks) {
            selectedStocks.map(stock => {
                const payload = {
                    id_audit: this.$route.params.id,
                    id_penyimpanan: stock.id,
                    stok: stock.stok,
                    jumlah: stock.stok,
                    penyimpanan: stock
                }
                this.rincians.push(payload)
            })
            this.$bvModal.hide('form-rincian-audit')
        },
        openAssetModal() {
            this.$bvModal.show('form-rincian-audit')
        },
        async getRincian() {
            this.rincians = await this.$store.dispatch('asset/getRincianAudit', {id_audit: this.$route.params.id})
        },
        async getPenyimpanan() {
            const params = {order: 'desc'}
            if(this.myGudang) params.gudang_id = this.myGudang.id
            await this.$store.dispatch('asset/getStock', params)
        },
        async submit() {
            if(this.rincians.length < 1) {
                this.displayError({
                    message: 'Harap pilih aset untuk diaudit!'
                })
                return false
            }

            const payloads = Object.assign([], this.rincians)
            payloads.map(item => delete item.penyimpanan)

            try {
                this.loading = true
                await this.$store.dispatch('asset/saveAuditRincian', payloads)
                this.loading = false
                this.displaySuccess({
                    message: 'Daftar aset untuk audit berhasil disimpan!'
                })
                this.getRincian()
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        }
    },
    created() {
        if(!this.audit) {
            this.$router.push(`/asset-audit`)
        }
        this.getPenyimpanan()
        this.getRincian()
    }
}
</script>